import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Link } from "../../components/link";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Amazon Web Services",
    description: "La piattaforma di servizi cloud leader di settore.",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Amazon Web Services" },
];

const AWS = ({ data, location }) => {
  const { intro, cloud, awsdata, partner } = data;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Amazon Web Services`}
        description={`Amazon Web services offre servizi di cloud computing affidabili, scalabili e economici in risposta alle esigenze del mercato. Ecco tutto quello che si deve sapere!`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                <a
                  href="https://aws.amazon.com/it/"
                  title="Amazon Web Services"
                  rel="noopener noreferrer"
                >
                  Amazon Web Services (AWS)
                </a>{" "}
                è un servizio basato sul Cloud che risponde ad alcune esigenze specifiche delle
                imprese. Infatti, per rispondere alle nuove esigenze imposte dal mercato - in
                termini di maggiore rapidità e flessibilità nell’erogazione dei servizi alla
                clientela - le aziende valutano con sempre maggiore attenzione i benefici che offre
                loro la tecnologia e il Cloud computing. In questo articolo andremo a evidenziare in
                sintesi le principali possibilità.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "amazon-web-services-servizi-di-cloud-computing",
                        "cloud-aws-tutti-i-vantaggi-della-soluzione",
                        "data-center-e-cloud-server-amazon-quello-che-devi-sapere",
                        "come-scegliere-il-partner-per-adottare-le-soluzioni-aws-in-italia",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#amazon-web-services-servizi-di-cloud-computing`}
                          to={`amazon-web-services-servizi-di-cloud-computing`}
                          className="anchor-nav__link"
                          title="Amazon Web Services: servizi di cloud computing"
                        >
                          Amazon Web Services: servizi di cloud computing
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#cloud-aws-tutti-i-vantaggi-della-soluzione`}
                          to={`cloud-aws-tutti-i-vantaggi-della-soluzione`}
                          className="anchor-nav__link"
                          title="Cloud AWS: tutti i vantaggi della soluzione "
                        >
                          Cloud AWS: tutti i vantaggi della soluzione
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#data-center-e-cloud-server-amazon-quello-che-devi-sapere`}
                          to={`data-center-e-cloud-server-amazon-quello-che-devi-sapere`}
                          className="anchor-nav__link"
                          title="Data Center e Cloud Server Amazon: quello che devi sapere"
                        >
                          Data Center e Cloud Server Amazon: quello che devi sapere
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#come-scegliere-il-partner-per-adottare-le-soluzioni-aws-in-italia`}
                          to={`come-scegliere-il-partner-per-adottare-le-soluzioni-aws-in-italia`}
                          className="anchor-nav__link"
                          title="Come scegliere il Partner per adottare le soluzioni AWS in Italia"
                        >
                          Come scegliere il Partner per adottare le soluzioni AWS in Italia
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="amazon-web-services-servizi-di-cloud-computing" className="anchor">
                  <h2 className="anchor-text__title">
                    Amazon Web Services: servizi di cloud computing
                  </h2>
                  <p>
                    Parlando di AWS ci riferiamo a servizi basati sul Cloud computing, una
                    tecnologia che garantisce alcuni vantaggi essenziali rispetto a quanto ricercano
                    le aziende. Il Cloud computing consiste nella distribuzione on-demand delle
                    risorse IT tramite Internet, con una tariffazione basata sull’effettivo consumo.
                    Piuttosto che acquistare, possedere e mantenere i Data Center ed i server
                    fisici, è possibile accedere ai servizi tecnologici - quali capacità di calcolo,
                    storage e database - sulla base delle proprie necessità affidandosi ad un
                    fornitore Cloud esterno, quale appunto AWS.
                  </p>
                  <p>Ecco alcuni dei principali servizi relativi al Cloud computing: </p>
                  <ul>
                    <li>
                      Maggiore <strong>continuità</strong> di servizio
                    </li>
                    <li>
                      Maggiore <strong>scalabilità</strong> dei sistemi
                    </li>
                    <li>
                      Accesso facilitato a <strong>tecnologie innovative</strong> e allo stato
                      dell’arte
                    </li>
                    <li>
                      Applicazioni software con una <strong>migliore funzionalità</strong>
                    </li>
                    <li>
                      Minore <strong>tempo</strong> di realizzazione delle iniziative IT
                    </li>
                    <li>
                      Riduzione dei <strong>costi</strong> IT
                    </li>
                    <li>
                      <strong>Erogazione più efficiente</strong> di servizi in diverse aree
                      geografiche
                    </li>
                    <li>
                      Migliore gestione della <strong>sicurezza</strong> dei sistemi
                    </li>
                    <li>
                      Minori oneri di <strong>gestione operativa</strong> per il personale
                    </li>
                  </ul>
                  <p>
                    Il Cloud computing rappresenta un cambio di paradigma non solo per quanto
                    riguarda la gestione dei sistemi IT ma anche e soprattutto nello sviluppo di
                    nuove applicazioni software basate sui microservizi.
                  </p>
                  <p>
                    Ariadne, in qualità di Consulting Partner AWS, si propone ad Aziende Private e
                    Pubbliche Amministrazioni per la realizzazione di progetti di Digital
                    Transformation che consentano di raggiungere i benefici richiesti grazie alle
                    nuove architetture software Cloud basate sui microservizi.
                  </p>
                </div>
                <div id="cloud-aws-tutti-i-vantaggi-della-soluzione" className="anchor">
                  <GatsbyImage image={getImage(cloud)} alt="Cloud" className="anchor-text__img" />
                  <h2 className="anchor-text__title">
                    Cloud AWS: tutti i vantaggi della soluzione
                  </h2>
                  <p>
                    AWS è la <strong>piattaforma cloud più completa sul mercato</strong> e offre ben
                    175 servizi di Data Center a livello globale. Milioni di clienti di ogni genere
                    utilizzano AWS per diminuire i costi di gestione delle proprie infrastrutture
                    IT, diventare più flessibili e innovarsi in modo più rapido.{" "}
                  </p>
                  <p>
                    <strong>
                      AWS è progettata per essere l’ambiente di cloud computing più sicuro sul
                      mercato e per rispettare i requisiti di sicurezza più stringenti.
                    </strong>
                  </p>
                  <p>
                    Per fare questo sono stati realizzati strumenti di sicurezza composti da ben 230
                    servizi, conformità e governance. AWS{" "}
                    <strong>supporta 90 standard di sicurezza e certificati di conformità</strong>;
                    AWS presta massima attenzione nella gestione dei dati e tutti i 117 servizi AWS
                    che archiviano i dati dei clienti offrono la possibilità di poterli
                    crittografare.{" "}
                  </p>
                  <p>
                    AWS è la piattaforma cloud più <strong>estesa</strong>. È organizzata e
                    distribuita globalmente in Regioni, ognuna con più zone di disponibilità
                    connesse in bassa latenza, elevate prestazioni di rete e ridondanza. AWS dispone
                    di 77 zone di disponibilità distribuite in 24 Regioni nel mondo. Altre 18 zone
                    di disponibilità e 6 nuove Regioni sono già state annunciate e saranno
                    disponibili a breve.
                  </p>
                  <p>
                    <strong>
                      Che vantaggi competitivi offre AWS alle imprese che lo adottano?
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <strong>Agilità</strong>
                    </li>
                  </ul>
                  <p>
                    Con il Cloud computing si ottiene la capacità di innovare più rapidamente e
                    supportare meglio il business nel time-to-market di nuovi prodotti e servizi. Il
                    Cloud AWS consente di accedere in modo semplice a tecnologie software differenti
                    e aumentare le risorse infrastrutturali necessarie in modo flessibile, sulla
                    base dell’effettivo utilizzo.
                  </p>
                  <ul>
                    <li>
                      <strong>Elasticità</strong>
                    </li>
                  </ul>
                  <p>
                    Con il Cloud computing non è necessario allocare in anticipo una quantità
                    maggiore di risorse infrastrutturali per la gestione dei picchi di richieste
                    degli utenti. È sufficiente effettuare il <em>provisioning</em> della quantità
                    di risorse effettivamente necessarie, aumentandole o riducendole in modo
                    istantaneo in base alle richieste.
                  </p>
                  <ul>
                    <li>
                      <strong>Risparmio sui costi</strong>
                    </li>
                  </ul>
                  <p>
                    Con il Cloud computing si evitano le spese in conto capitale per il Data Center
                    in favore di una spesa variabile, pagando i servizi in base al reale consumo
                    effettivo. Le spese variabili sono sensibilmente inferiori rispetto al costo che
                    si sosterrebbe autonomamente, grazie alle maggiori economie di scala.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>Distribuzione globale in pochi minuti</strong>
                    </li>
                  </ul>
                  <p>
                    Con il Cloud computing si possono distribuire in modo globale in pochi minuti le
                    applicazioni ed i contenuti informativi dell’azienda. In questo modo le
                    applicazioni sono più vicine agli utenti finali riducendo la latenza di rete e
                    migliorando l’usabilità dei software.
                  </p>
                </div>
                <div
                  id="data-center-e-cloud-server-amazon-quello-che-devi-sapere"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(awsdata)}
                    alt="AWS data"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Data Center e Cloud Server Amazon: quello che devi sapere
                  </h2>
                  <p>
                    Per Public Cloud si intende il modello che rende possibile l’accesso su
                    richiesta a un insieme condiviso di risorse (infrastrutturali e/o applicative)
                    rapidamente allocabili o rilasciabili in autonomia e con un minimo sforzo di
                    gestione.
                  </p>
                  <p>
                    Le soluzioni fruite in modalità Public Cloud vengono erogate da
                    un’infrastruttura appartenente a un service provider quale AWS e il cliente può
                    utilizzarle in modalità self-service in modo scalabile e misurabile, pagando a
                    consumo.
                  </p>
                  <p>
                    L’offerta comprende: <strong>servizi SaaS</strong> (Software as a Service –
                    applicazioni enterprise, di produttività personale e altre soluzioni verticali),
                    servizi PaaS (Platform as a Service – piattaforme e framework applicativi,
                    database e data services, integrazione e sviluppo applicativo) e{" "}
                    <strong>servizi IaaS</strong> (Infrastructure as a Service – macchine virtuali,
                    storage, disaster recovery, business continuity).
                  </p>
                  <ul>
                    <li>
                      <strong>Infrastructure as a Service (IaaS)</strong>
                    </li>
                  </ul>
                  <p>
                    L’IaaS fornisce l’accesso ai servizi di rete, computer virtuali e spazi di
                    archiviazione dei dati. L’IaaS offre il più elevato livello di flessibilità e
                    controllo gestionale delle risorse IT.
                  </p>
                  <ul>
                    <li>
                      <strong>Platform as a Service (PaaS)</strong>{" "}
                    </li>
                  </ul>
                  <p>
                    Con il PaaS non è più necessario gestire l’infrastruttura IT come hardware e
                    sistemi operativi. Ci si concentra sulla gestione e distribuzione delle
                    applicazioni, senza preoccuparsi più dell’approvvigionamento delle risorse,
                    della pianificazione delle capacità, della manutenzione del software.
                  </p>
                  <ul>
                    <li>
                      <strong>Software as a Service (SaaS)</strong>{" "}
                    </li>
                  </ul>
                  <p>
                    Il SaaS offre un prodotto software completo che viene eseguito e gestito dal
                    fornitore di servizi. Il cliente si concentra solo nell’utilizzo
                    dell’applicazione senza avere problemi di gestione.{" "}
                  </p>
                </div>
                <div
                  id="come-scegliere-il-partner-per-adottare-le-soluzioni-aws-in-italia"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(partner)}
                    alt="Image partner"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Come scegliere il Partner per adottare le soluzioni AWS in Italia
                  </h2>
                  <p>
                    Le Direzioni IT aziendali stanno vivendo un momento di profondo cambiamento: il
                    Cloud rende più accessibile la trasformazione digitale ma trasforma radicalmente
                    il ruolo, i processi e le competenze di chi si occupa di tecnologia.
                  </p>
                  <p>
                    Molte aziende che utilizzano servizi Public Cloud percepiscono un problema di
                    competenze da colmare nella loro gestione e per rendersi pronte a governare in
                    modo efficace il Cloud si orientano verso due strategie sinergiche: da un lato
                    si affidano a consulenti esterni per reperire competenze specifiche, dall’altro
                    potenziano il know-how interno, formando il personale IT e assumendo nuove
                    figure professionali dedicate.
                  </p>
                  <p>
                    Queste competenze si stanno consolidando nelle{" "}
                    <strong>
                      aziende, ormai consapevoli della rilevanza strategica del Cloud per l’IT
                    </strong>
                    , con percorsi che portano in un numero crescente di casi alla creazione di veri
                    e propri centri di eccellenza Cloud con l’obiettivo di guidare questo percorso
                    di trasformazione.{" "}
                  </p>
                  <p>
                    <strong>
                      Ariadne, in qualità di Consulting Partner AWS, dispone delle competenze
                      tecniche necessarie per affiancare le Direzioni IT aziendali nel rendere il
                      Cloud accessibile alla propria organizzazione e nello sviluppo di nuove
                      applicazioni software basate sui microservizi AWS
                    </strong>{" "}
                    <Link to="/competence-center/aws/cloud-native-apps/">
                      <strong>(Cloud Native Applications)</strong>
                    </Link>
                  </p>
                  <p>
                    La scelta di Ariadne rappresenta il giusto mix tra esperienza nella
                    progettazione e realizzazione di applicazioni web innovative e la consulenza
                    aziendale orientata a raccogliere i benefici offerti dalla digital experience.
                  </p>
                  <p>
                    Per fare consulenza digitale sono necessarie due capacità essenziali: un metodo
                    consolidato che per noi è Agile e Lean Project Management, in aggiunta a
                    competenze multidisciplinari con il giusto equilibrio tra loro.
                  </p>
                  <p>
                    <em>
                      <strong>
                        Ariadne è la società di Cloud Transformation del Gruppo Ariadne Digital
                      </strong>{" "}
                      che in prima persona si occupa dei clienti e del governo dei progetti,
                      coinvolgendo di volta in volta parti del suo gruppo e facendole lavorare
                      all’unisono nel rispetto delle rispettive competenze. In questo momento di
                      profonda trasformazione dei mercati, sempre più distribuiti equamente tra
                      l’esperienza tradizionale e quella nuova, basata sul digitale, per le aziende
                      che sono impegnate nel processo strategico di trasformazione digitale è
                      fondamentale disporre di competenze e organizzazioni mirate allo scopo. In
                      definitiva, quello che mettiamo in atto con il Gruppo Ariadne Digital per i
                      nostri clienti è una vera e propria{" "}
                      <strong>Digital Transformation Consulting</strong>.
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default AWS;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/aws.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    cloud: file(relativePath: { eq: "pages/aws-cloud.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    awsdata: file(relativePath: { eq: "pages/aws-data-center.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    partner: file(relativePath: { eq: "pages/aws-partner.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
